import React from 'react';

const BrandList = [
    {
        image: './images/service/imagen3.png'
    },
    {
        image: './images/service/imagen4.png'
    },

    {
        image: './images/service/imagen5.png'
    },

    {
        image: './images/service/imagen6.png'
    },
    {
        image: './images/service/imagen7.jpg'
    },
    {
        image: './images/service/imagen8.jpg'
    },
    {
        image: './images/service/imagen9.png'
    }
    ,
    {
        image: './images/service/imagen10.png'
    },

    {
        image: './images/service/imagen11.png'
    }
    ,
    {
        image: './images/service/imagen12.png'
    }
    ,
    {
        image: './images/service/imagen13.png'
    }
    ,
    {
        image: './images/service/imagen14.jpg'
    }
    ,
    {
        image: './images/service/imagen15.jpg'
    }
    ,
    {
        image: './images/service/imagen16.png'
    }
  
]

const BrandThree = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandThree;
