import React from "react";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const TeamFour = ({ portfolio, column, cardStyle, textAlign }) => {
  return (
    <div className="row row--15">
      <div className={`${column}`}>
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <div className={` ${textAlign}`}>
            <div className="inner">
              <div className="image">
                <Link to="/servicios">
                  <img src={`${portfolio.portfolioImage}`} alt="card Images" />
                </Link>
              </div>
              <div className="content">
                <h4 className="title mb--20">
                  <Link
                    to="/servicios"
                    dangerouslySetInnerHTML={{ __html: portfolio.title }}
                  ></Link>
                </h4>
                {/* <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: portfolio.body}}></p> */}

              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};
export default TeamFour;
