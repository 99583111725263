import React from 'react';
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from 'react-icons/fi';
import Whatsapp from "../../components/pageToTop/contactos/Whatsapp";

const FooterOne = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default">
                <div className="footer-top">
                    <div className="container text-center">
                        <div className="row">
                            {/* Start Single Widget  */}

                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="rn-footer-widget">
                                    <div className="logo">
                                    <div className="thumbnail">
              <img
                className="w-30 abount-i"
                src="./limpiarte/footer-img.png"
                alt="About Images"
              />
            </div>
                                    </div>

                                </div>
                            </div>
                            {/* End Single Widget  */}

                            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                <div className="rn-footer-widget">
                        
                            <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h1 className="text-center"> Nuestras <span className='title-principal-violeta'>redes</span></h1>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <ul className="social-icon social-default">
                      <li>
                        <a
                          href="https://www.facebook.com/people/Limpiarte/61561540297179/?mibextid=ZbWKwL"
                          target="_blank"
                        >
                          <FiFacebook />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                        >
                          <FiTwitter />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                        >
                          <FiInstagram />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                        >
                          <FiLinkedin />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                </div></div>
                          

                        </div>
                    </div>
                </div>
            </footer>
            <Whatsapp />
            <ScrollTop />
        </>
    )
}

export default FooterOne
