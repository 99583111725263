import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FaLevelUpAlt } from 'react-icons/fa';

const ServiceList = [
    {
        icon: 'recurso13.png',
        title: 'Misión',
        description: 'Cumplir satisfactoriamente los requerimientos de limpieza de nuestros clientes, proveedores y socios comerciales, mediante la mejora continua de nuestros servicios, manejo ecológico y la eficiencia de los procesos.'
    },
    {
        icon: 'recurso12.png',
        title: 'Visión',
        description: 'Aspiramos el liderazgo paraguayo en el rubro de limpieza en los proximos 5 años, a través de nuestros valores y procesos de mejora continua.'
    },
    {
        icon: 'recurso11.png',
        title: 'Valores',
        description: '1- Somos amables.<br/>2- Siempre sonreímos.<br/>3- Nos gusta trabajar en equipo.<br/> 4- El cliente es como si fuese de la familia. <br/> 5- Ayudamos, sin pedir nada a cambio.<br/> 6- Amamos la naturaleza, fauna y flora, porque cuidamos el lugar donde vivimos.<br/> 7- Nos gusta dar más del 100%.<br/> 8- Somos sencillos como personas y en todos los procesos del trabajo.'
    }
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                            <img src={`./limpiarte/${val.icon}`} alt="agencia de marketing en Paraguay" className='icon-service-1'/>
                            </div>
                            <div className="content">
                                <h4 className="title w-600 title-principal"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description mb--0 centrar-texto" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;