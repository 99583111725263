import React, { useState, useEffect } from "react";
import PortfolioData from "../../data/portfolio/PortfolioData.json";
import PortfolioItem from "./PortfolioItem";
import { FaSpinner } from "react-icons/fa";
import TeamFour from "../team/TeamFour";

const filters = [
    {
        id: 1,
        text: "All",
      },
    {
      id: 2,
      text: "estrategias de marketing",
    },
    {
      id: 3,
      text: "publicidad online",
    },
    {
      id: 4,
      text: "social media",
    },
    {
      id: 5,
      text: "diseño y desarrollo web",
    },
    {
      id: 6,
      text: "diseño gráfico",
    }
    ,
    {
      id: 7,
      text: "seo",
    }
    
];



const alldata = PortfolioData;
const PortfolioOne = ({ Column }) => {
    const [getAllItems] = useState(alldata);
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [dataIncrement] = useState(6) ;
    const [noMorePost, setNoMorePost] = useState(false);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);
    useEffect(() => {
        setActiveFilter(filters[0].text.toLowerCase());
        setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
    }, []);


    
    return (
        <>


            <div className="row row--15">
                {visibleItems.map((item) => (
                    <div key={item.id} className={Column}>
                        {/* <PortfolioItem portfolio={item} /> */}
                        <TeamFour portfolio={item} teamStyle="team-style-three"cardStyle = "card-style-1" textAlign = "text-start" />
                    </div>
                ))}
            </div>

            {/* <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rwt-load-more text-center mt--50">
                        <button
                            className="btn btn-default btn-large btn-icon"
                            onClick={handleLoadmore}
                            disabled={noMorePost ? "disabled" : null}
                        >
                            {noMorePost ? (
                            "No Item Here"
                            ) : (
                            <span>
                                Load More 
                                <span className="icon">
                                    <FaSpinner />
                                </span>
                            </span>
                            )}
                        </button>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default PortfolioOne;
