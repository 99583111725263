import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { FiCheck } from "react-icons/fi";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BlogClassicData from "../data/blog/BlogList.json";
import HeaderOne from "../common/header/HeaderOne";
import Explicacion from "../elements/desarrollo/Explicacion";
import ServiceOne from "../elements/desarrollo/ServiceOne";
import FooterTwo from "../common/footer/FooterTwo";
import TimelineOne from "../elements/timeline/TimelineOne";
import TimelineTwo from "../elements/desarrollo/TimelineTwo";
import CalltoActionOne from "../elements/calltoaction/CalltoActionOne";
import BrandThree from "../elements/brand/BrandThree";
import Copyright from "../common/footer/Copyright";
import FooterOne from "../common/footer/FooterOne";
var BlogListData = BlogClassicData.slice(0, 3);

const ProgramacionDetails = () => {
  return (
    <>
      <SEO title="Limpiarte- Mafara S.A || Servicio integrales de limpieza" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-650">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h1 className="title-principal">
                    Somos{" "}
                    <span className="title-principal-violeta">Limpiarte</span>
                  </h1>
                  <p className="description">
                    Somos un equipo de personas, capacitadas y enfocadas en
                    limpiar los espacios asignados con excelencia y detalle.
                    Trabajamos en equipo y apreciamos la amabilidad hacia
                    nuestros clientes.
                  </p>
                  <div className="container row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <a href="./pdf/politica-ambiental.pdf" target="_blank">
                        {" "}
                        <img
                          className="abount-i w-100"
                          src="./limpiarte/recurso224.png"
                          alt="Política ambiental"
                        />
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <a href="./pdf/politica-calidad.pdf" target="_blank">
                        <img
                          className="abount-i w-100"
                          src="./limpiarte/recurso223.png"
                          alt="Nuestra política de calidad"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-1 order-lg-2 col-lg-5">
                <div className="thumbnail">
                  <img
                    src="./limpiarte/recurso113.png"
                    alt="Agencia de diseño multimedial"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />
        <Explicacion />

        <Separator />
        <div className="slider-area slider-style-3">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-12 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h1 className="title-principal">Maquinarias</h1>
                  <ul className="list-icon">
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Varas telescópicas para limpieza de vidrios.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Carros de servicio, carros con prensa mopas simples y dobles.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Carteles con leyendas de: Piso mojado, cuidado.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Escobas que no levantan polvo.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Alfombras absorbentes grandes (para lluvia).</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Mantenedoras tipo industrial que limpia, pule, encera y lava alfombras, con cepillos de cerda, nylon y paños sintéticos.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Aspiradoras de polvo tipo industrial, con capacidad mínima de 3.000 m²/hora.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Aspiradoras de polvo tipo vertical, con una capacidad mínima de 200 m²/hora.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Aspiradoras de líquido con una capacidad mínima de 200 m²/hora.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Hidrolavadoras a presión con motor trifásico para agua caliente y fría para el lavado de los pisos.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Barredoras aspiradoras de uso externo.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Dispensers de papel higiénico, de manos, y jabón líquido.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Camión de la marca Isuzu con una capacidad de 5 toneladas.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Camioneta Isuzu.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Chevrolet S10.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Toyota Hiace.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Kia Picanto.</p></li>
<li><p><span className="icon" style={{color:"white"}}><FiCheck /></span> Motoneta Kenton Dakar.</p></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="title-principal">
                  Nuestras{" "}
                  <span className="title-principal-violeta"> Bases</span>
                </h1>
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25"
              textAlign="text-center"
            />
          </div>
        </div>
        <br />
        <br />
        <div className="rwt-brand-area pb--80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h1 className="title-principal">
                    Clientes que{" "}
                    <span className="title-principal-violeta">
                      Confian en nosotros
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <BrandThree brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div>

        <CalltoActionOne />

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default ProgramacionDetails;
