import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BlogClassicData from "../data/blog/BlogList.json";
import HeaderOne from "../common/header/HeaderOne";
import Explicacion from "../elements/desarrollo/Explicacion";
import ServiceOne from "../elements/desarrollo/ServiceOne";
import FooterTwo from "../common/footer/FooterTwo";
import TimelineOne from "../elements/timeline/TimelineOne";
import TimelineTwo from "../elements/desarrollo/TimelineTwo";
import CalltoActionOne from "../elements/calltoaction/CalltoActionOne";
import Copyright from "../common/footer/Copyright";
import FooterOne from "../common/footer/FooterOne";
var BlogListData = BlogClassicData.slice(0, 3);

const ProgramacionDetails = () => {
  return (
    <>
      <SEO title="Limpiarte- Mafara S.A || Servicio integrales de limpieza" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-650">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h1 className="title-principal">Limpieza <span className="title-principal-violeta">general</span></h1>
                  <p className="description">
                  Servicios de Limpieza en general con el detalle que cada empresa merece.
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                                      <a href ="https://api.whatsapp.com/send?phone=595994652377" target="_blank">
                  <img
                className="abount-i abount-boton"
                src="./limpiarte/recurso222.png"
                alt="solicita cotizacion"
              />
                  </a>
                  </div>
                  <br/>
                </div>
              </div>
              <div className="order-1 order-lg-2 col-lg-5">
                <div className="thumbnail">
                  <img
                    src="./limpiarte/l-general.png"
                    alt="Agencia de diseño multimedial"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* <Separator /> */}
        <div className="rwt-about-area rn-section-gap">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    src="./limpiarte/l-desinfeccion.png"
                    alt="Agencia de diseño multimedial"
                  />
                </div>
              </div>

              <div className="col-lg-7 mt_md--40 mt_sm--40">
                <div className="content">
                  <div className="section-title">
                    <h1 className="title-principal">Desinfección</h1>
                    <p>
                    Desinfección de espacios para clínicas, hospitales, centros o instituciones de salud o industria farmaceútica.
                    </p>
                    <div className="button-group mt--30 mt_sm--20">
                     <a href ="https://api.whatsapp.com/send?phone=595994652377" target="_blank">
                  <img
                className="abount-i abount-boton"
                src="./limpiarte/recurso222.png"
                alt="solicita cotizacion"
              />
                  </a>
                  
                  </div>
                  <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Separator /> */}
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-650">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h1 className="title-principal">Fumigación</h1>
                  <p className="description">
                  Servicios de Fumigación, en espacios abiertos o cerrados, con materiales amigables con el medio ambiente y el cuidado de la salud de seres humanos, flora y fauna.
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                                      <a href ="https://api.whatsapp.com/send?phone=595994652377" target="_blank">
                  <img
                className="abount-i abount-boton"
                src="./limpiarte/recurso222.png"
                alt="solicita cotizacion"
              />
                  </a>
                  </div>
                  <br/>
                </div>
              </div>
              <div className="order-1 order-lg-2 col-lg-5">
                <div className="thumbnail">
                  <img
                    src="./limpiarte/l-fumigacion.png"
                    alt="Agencia de diseño multimedial"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Separator /> */}
        <div className="rwt-about-area rn-section-gap">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    src="./limpiarte/l-mantenimiento.png"
                    alt="Agencia de diseño multimedial"
                  />
                </div>
              </div>

              <div className="col-lg-7 mt_md--40 mt_sm--40">
                <div className="content">
                  <div className="section-title">
                  <h1 className="title-principal">Mantenimiento de <span className="title-principal-violeta">áreas verdes</span></h1>
                    <p>
                      Servicios de Limpieza y mantenimiento de áreas verdes.
                    </p>
                    <div className="button-group mt--30 mt_sm--20">
                                      <a href ="https://api.whatsapp.com/send?phone=595994652377" target="_blank">
                  <img
                className="abount-i abount-boton"
                src="./limpiarte/recurso222.png"
                alt="solicita cotizacion"
              />
                  </a>
                  </div>
                  <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                {/* <Separator /> */}
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-650">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                <h1 className="title-principal">Limpieza en <span className="title-principal-violeta">alturas</span></h1>
                <p>
                    Limpieza de vidrios en altura, con todas las medidas de seguridad tanto de nuestro personal como de nuestros clientes.
                    </p>
                  <div className="button-group mt--30 mt_sm--20">
                                      <a href ="https://api.whatsapp.com/send?phone=595994652377" target="_blank">
                  <img
                className="abount-i abount-boton"
                src="./limpiarte/recurso222.png"
                alt="solicita cotizacion"
              />
                  </a>
                  </div>
                  <br/>
                </div>
              </div>
              <div className="order-1 order-lg-2 col-lg-5">
                <div className="thumbnail">
                  <img
                    src="./limpiarte/l-vidrios.png"
                    alt="Agencia de diseño multimedial"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>



        <FooterOne/>
        <Copyright/>
      </main>
    </>
  );
};
export default ProgramacionDetails;
