import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Explicacion from "../diseñografico/Explicacion";
import { FiCheck } from "react-icons/fi";

const CalltoActionOne = () => {
  return (
    <div
      className="rn-callto-action clltoaction-style-default bg_image bg_image_fixed"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/limpiarte/banner.webp)`,
      }}
      data-black-overlay="7"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h1 className="subtitle">POR QUÉ <span className='title-principal-violeta'>ELEGIRNOS</span></h1>
                </ScrollAnimation>

    
                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Somos una empresa que apuesta por la alta capacitación de sus recursos humanos, en la mejora contínua y estamos comprometidos con el detalle
en cada metro de limpieza que nos asignan.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> El cliente quiere detalle y nosotros somos especialistas en ello.</li>
                                        <li><span className="icon"><FiCheck /></span> Nuestros materiales de limpieza son de alta gama.</li>
                                        <li><span className="icon"><FiCheck /></span> Respetamos el medio ambiente.</li>
                                        <li><span className="icon"><FiCheck /></span> Amamos nuestro país y su gente.</li>
                                    </ul>
                                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <a className="" href="/cotizacion">
                  <img
                className="abount-i abount-boton"
                src="./limpiarte/recurso222.png"
                alt="MÁS INFORMACIÓN"
              />
                  </a>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionOne;
