import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 

import BusinessConsulting2 from "./pages/BusinessConsulting2";
import Contact from "./elements/contact/Contact";
// Import Css Here 
import './assets/scss/style.scss';
import Trabajo from './pages/Trabajo';
import Landing from './pages/Landing';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                   
                    <Route path={`${process.env.PUBLIC_URL + "/"}`}exact component={BusinessConsulting2}/>

                    <Route path={`${process.env.PUBLIC_URL + "/nosotros"}`}exact component={Landing}/>

                    <Route path={`${process.env.PUBLIC_URL + "/contacto"}`} exact component={Contact}/>

                    <Route path={`${process.env.PUBLIC_URL + "/servicios"}`} exact component={Trabajo}/>


                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
