import React from "react";
import Typed from "react-typed";

const Explicacion = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                src="./limpiarte/historia.png"
                alt="Agencia de diseño multimedial"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h1 className="title-principal">
                  Nuestra{" "}
                  <span className="title-principal-violeta">Historia</span>
                </h1>
                <p>
                  Limpiarte es una empresa de MAFARA S.A, fundada en el año
                  2013, especializada en la prestación de servicios integrales
                  de limpieza y desinfección, con un plantel humano
                  experimentado, en permanente formación y actualización abocado
                  en brindar servicios de calidad.{" "}
                </p>
                <p>
                  Para demostrar ese compromiso, Limpiarte de MAFARA S.A ha
                  establecido un Sistema de Gestión de Calidad basado en los
                  requisitos de las Normas ISO orientada hacia la Satisfacción
                  de los clientes con un fuerte compromiso de toda la
                  organización en cumplir con sus necesidades y expectativas,
                  así como los requisitos legales, de las partes interesadas,
                  los propios del servicio y la mejora continua, e ISO
                  14001:2015 Sistema de Gestión Ambiental donde fomenta y
                  sensibiliza al personal de la organización sobre la
                  importancia de la protección y conservación del ambiente.
                </p>
                {/* <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>More About Us</span></a>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explicacion;
