import React from "react";
import Typed from "react-typed";

const AboutOne = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100 abount-i"
                src="./limpiarte/recurso114.png"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h1 className="title-principal">
                Excelencia en Limpieza:
                </h1>
                <p>
                 Bienvenidos a Limpiarte! Somos una destacada empresa de limpieza en Paraguaya, comprometida con la excelencia desde el año 2013. 
                </p>
                <p>
                Nos especializamos en la higienización y limpieza de una amplia gama de espacios, incluyendo empresas, oficinas, plantas industriales, hospitales y clínicas en todo el territorio Paraguayo. 
                </p>
                <div className="read-more-btn mt--40">
                  <a className="" href="/nosotros">
                  <img
                className="abount-i abount-boton"
                src="./limpiarte/masinfo.png"
                alt="MÁS INFORMACIÓN"
              />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
