import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import Slider from "react-slick";
import FooterTwo from '../common/footer/FooterTwo';
import FooterOne from '../common/footer/FooterOne.js';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import HeaderOne from '../common/header/HeaderOne';
import PortfolioOne from '../elements/portfolio/PortfolioOne';
import CalltoActionOne from '../elements/calltoaction/CalltoActionOne';
import { BannerActivation } from "../utils/script";
import CounterUpFour from "../elements/counterup/CounterUpFour.js"
const BannerData = [
    {
      image: "./limpiarte/limpiartehome.jpg",
      description:
        "La higiene y el cuidado del medio ambiente son nuestra mayor responsabilidad cuando ofrecemos nuestros servicios.  ",
    },
  ];


const BusinessConsulting2 = () => {
    return (
        <>
            <SEO title="Limpiarte- Mafara S.A || Servicio integrales de limpieza" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

           

        {/* Start Slider Area  */}
        <Slider
          className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-650 bg-overlay bg_image"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-7">
                      <div className="inner text-start">
                        <h1
                          className="title"
                        ><span className='title-principal-celeste'>Servicios integrales</span> para empresas</h1>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
                {/* <Separator />                             */}
                <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
              
                <div id="quienes-somos">
                <AboutOne />
                </div>
                <Separator />
                <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">


          <div className="col-lg-4 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h1 className="title-principal">
                Normas ISO
                </h1>
                <p>
              Somos una empresa certificada por normas ISO. 
                </p>

              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="thumbnail">
              <img
                className="w-30 abount-i"
                src="./limpiarte/imagen1.png"
                alt="About Images"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="thumbnail">
              <img
                className="w-30 abount-i"
                src="./limpiarte/imagen2.png"
                alt="About Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>                            
                {/* End About Area  */}
                <Separator />
                <br/>
                <div className="rwt-portfolio-area rn-section-gap" id="servicios">
                    <div className="container">
                        <div className="row">
                         
                        <h1 className="title-principal">
                Nuestros servicios
                </h1>
                        </div>
                        
                        
                        <PortfolioOne Column="col-lg-6 col-md-6 col-sm-6 col-12 mt--30 portfolio no-overlay"  />
                        
                    </div>
                </div>
  <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        <CalltoActionOne />
                        
                    </div>
                </div>
                <FooterOne/>
                <Copyright />
            </main>
        </>
    )
}
export default BusinessConsulting2;
