import React from "react";
import { Link } from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Logo from "../../elements/logo/Logo";
import Whatsapp from "../../components/pageToTop/contactos/Whatsapp";

const footerIntem = footerOne[0];
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];
const footerIntemFive = footerOne[5];

const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;
const indexThreeLink = footerIntemThree.quicklink;

const FooterTwo = () => {
  return (
    <>
      <footer className="rn-footer footer-style-default variation-two">
        {/* <CalltoActionSeven /> */}
        <div className="footer-top">

          <div className="container">
            
            <div className="row">
                
              <div className="rwt-social-area rn-section-gap">
                
                <div className="container">
                    
                  <div className="row">
                    <div className="col-lg-12">
                      <h1 className="text-center"> Nuestras redes</h1>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <ul className="social-icon social-default">
                      <li>
                        <a
                          href="#"
                          target="_blank"
                        >
                          <FiFacebook />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/DareAgencia"
                          target="_blank"
                        >
                          <FiTwitter />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/dareagenciadigital/"
                          target="_blank"
                        >
                          <FiInstagram />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/dare-agencia-digital/"
                          target="_blank"
                        >
                          <FiLinkedin />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Whatsapp />
      <ScrollTop />
    </>
  );
};

export default FooterTwo;
